import { StatusPill, Typography, Card, AlertMessage } from '@vartanainc/design-system';
import { Ref, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { useMutation, useReactiveVar } from '@apollo/client';
import { sessionVar } from '../../../../graphql/cache';
import { APP_NAMES } from '../../../CommonWidgetV2/commonWidget.constants';
import {
  PROGEN_CTA,
  SELLER_TOTAL_PAYOUT,
  VIEW_PAYMENT_PLAN,
  ZERO_CURRENCY_REGEX,
} from '../../../../constants/common.constants';
import { progenDefaultTexts } from '../../../../constants/progenTexts';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import { CalculatorResponseProps, INTEREST_RATE_TEXT } from '../widgetV2.constants';
import { ReactComponent as NotesStack } from '../../../../assets/notes_stack.svg';

import {
  showErrorToast,
  isZero,
  formatCurrency,
  removeTrailingZeros,
  getPageUrl,
  extractCreditTermInfo,
} from '../../../../utils/helpers';
import TabsSummary from '../TabsSummary/TabsSummary';
import {
  TertiaryActions,
  getPillStatus,
  isStatusPage,
  renderGuaranteeStatus,
  renderFeeTooltip,
  handlePageNavigate,
  isCompactScreen,
  isSmallScreen,
  getPayoutContainerClassNames,
  handleDownload,
  formatAmount,
} from '../widgetUtils';
import Calculator from '../Calculator/Calculator';
import LoaderMd from '../../../../components/LoaderMd/LoaderMd';
import '../../../CommonWidgetV2/CommonWidget.scss';
import {
  PRE_QUALIFIED_OFFER_LABEL,
  TCV_BELOW_APPROVED_ERROR,
} from '../../../Orders/order.constants';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { CurrencyPill } from '../../../../components/Pill/CurrencyPill';
import { WidgetContext } from '../../../../context/WidgetContext';
import VartanaLoader from '../../../../components/VartanaLoader/VartanaLoader';
import { GENERATE_PAYMENT_PLAN } from '../../../../graphql/queries/customer';
import OutlinedPill from '../../../../components/Pill/OutlinedPill/OutlinedPill';

interface InstallmentTabProps {
  status: string;
  tertiaryActions: {
    label: string;
    type: string;
    slug: string;
  }[];
  setActive: (tab: string) => void;
  setSubPage: (page: string) => void;
  toggleModal: (action) => void;
  isCrm: boolean;
  companyNumber: string | undefined;
  crmOpportunityId: string | undefined;
  handleCancelCreditCheckChangeRequest: () => void;
  calculatorRef: Ref<unknown>;
  setCanDownloadProposal: (canDownloadProposal: boolean) => void;
  showSubPage: boolean;
  setShowSubPage: (showSubPage: boolean | ((prev: boolean) => boolean)) => void;
  setHasCalculatorError: (hasCalculatorError: boolean) => void;
  handleGetHigherLimit: () => void;
  creatingChangeRequest: boolean;
  appName: string | null;
  currency: string;
  isPreQualifiedTerms: boolean;
  isTabLoading: boolean;
  isCustomSchedule: boolean;
}

interface ErrorProps {
  message: string;
  subsidy: string;
  spiff: string;
}

const InstallmentTab = ({
  status,
  tertiaryActions,
  setActive,
  setSubPage,
  toggleModal,
  isCrm,
  companyNumber = '',
  crmOpportunityId = '',
  handleCancelCreditCheckChangeRequest,
  setCanDownloadProposal,
  calculatorRef,
  showSubPage,
  setShowSubPage,
  setHasCalculatorError,
  handleGetHigherLimit,
  creatingChangeRequest,
  appName,
  currency,
  isPreQualifiedTerms,
  isTabLoading,
  isCustomSchedule,
}: InstallmentTabProps): JSX.Element => {
  const [response, setResponse] = useState<CalculatorResponseProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorProps>();
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  const [vendorFee, setVendorFee] = useState('$0');
  // subsidy error
  const [discountTooltip, setDiscountTooltip] = useState('');
  const [documentationFee, setDocumentationFee] = useState('');
  const [frequencyText, setFrequencyText] = useState('per month');

  const [spiffRate, setSpiffRate] = useState('');
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  const [spiffAmount, setSpiffAmount] = useState('$0');
  const [showSpiff, setShowSpiff] = useState(false);
  const [buyerInterestRate, setBuyerInterestRate] = useState<string>('');
  const [generatePaymentPlan] = useMutation(GENERATE_PAYMENT_PLAN);

  const navigate = useNavigate();
  const location = useLocation();
  const metaContext = useContext(WidgetMetaContext);
  const widgetContext = useContext(WidgetContext);

  const widthVariant = get(widgetContext, 'widthVariant', '');

  const sessionData = useReactiveVar(sessionVar);
  const showCurrencyPill = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled'
  );
  const shouldShowInterestRateToVendor = get(
    sessionData,
    'session.user.company.product.showRateToVendor'
  );

  const resetTab = useCallback(() => {
    setResponse(undefined);
    setError(undefined);
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    setVendorFee('$0');
    setDiscountTooltip('');
    setDocumentationFee('');
    setCanDownloadProposal(false);
  }, [setCanDownloadProposal]);

  useEffect(() => {
    if (!showSubPage) resetTab();
  }, [resetTab, showSubPage]);

  useEffect(() => {
    setCanDownloadProposal(false);
  }, [setCanDownloadProposal]);

  useEffect(() => {
    setActive('offerInstallment');
    setSubPage('');
    setShowSubPage(false);
  }, [setActive, setShowSubPage, setSubPage]);

  const toggleSubPage = (): void => {
    if (showSubPage) setSubPage('');
    setShowSubPage((prev) => !prev);
  };

  const handleTertiaryButtonClick = (action): void => {
    if (action.slug === PROGEN_CTA.modifyTerms) {
      if (isCrm) {
        const url = getPageUrl({
          customerNumber: companyNumber,
          otherParams: location.search,
          page: 'modifyTerms',
          expandedApp: true,
        });
        handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
      } else
        navigate(
          getPageUrl({
            customerNumber: companyNumber,
            page: 'modifyTerms',
          })
        );
    } else if (action.slug === PROGEN_CTA.creditInfo) {
      setSubPage('creditDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.submitInformation) {
      setSubPage('moreInformationDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.viewCreditDecision) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.learnMore) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.viewDecisionDetail) {
      toggleModal(action);
    } else if (action.slug === PROGEN_CTA.trackRequest) {
      setSubPage('trackRequestDetails');
      toggleSubPage();
    } else if (action.slug === PROGEN_CTA.cancelRequest) {
      handleCancelCreditCheckChangeRequest();
    } else {
      showErrorToast();
      reportError(`Unidentified action: ${JSON.stringify(action)}`);
    }
  };
  const handleValueChange = (resp): void => {
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    const totalFormattedFee = formatCurrency(get(resp, 'total_formatted_fee', '$0'));
    const totalSpiffRate = get(resp, 'spiff_rate', '');
    const totalSpiffAmount = get(resp, 'spiff_amount', '$0');
    const calculatedBuyerInterestRate = get(resp, 'buyer_interest_rate');
    const discountRate = removeTrailingZeros(
      get(resp, 'formatted_discount_rate_tooltip', '')
    )
      .replace('(', '')
      .replace(')', ' or ')
      .toLowerCase();
    const docFee = removeTrailingZeros(
      get(resp, 'vendor_application_fee_tooltip', '')
    ).toLowerCase();
    setResponse(resp);
    setError(Object.keys(resp.errors || {}).length !== 0 ? resp.errors : null);
    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
    setVendorFee(totalFormattedFee || '$0');
    setDiscountTooltip(discountRate || '$0 or 0% subsidy');
    setDocumentationFee(docFee || '$0 documentation fee');
    setHasCalculatorError(Object.keys(resp.errors || {}).length !== 0);
    setSpiffRate(totalSpiffRate);
    setSpiffAmount(totalSpiffAmount);
    setBuyerInterestRate(`${calculatedBuyerInterestRate} ${INTEREST_RATE_TEXT}`);
  };

  useEffect(() => {
    setShowSpiff(!isZero(spiffAmount));
  }, [spiffAmount]);

  const hasFee =
    get(response, 'vendor_application_fee_tooltip') ||
    get(response, 'formatted_discount_rate_tooltip') ||
    spiffRate === '0';

  const renderSpiffAmount = showSpiff && get(response, 'spiff_amount', false);

  const handleFrequencyChange = (frequency): void => {
    const { value } = frequency;
    setFrequencyText(progenDefaultTexts.frequency_labels[value] || '');
  };

  const isPreQualifiedOfferExpired = get(
    metaContext,
    'meta.isPreQualifiedOfferExpired',
    false
  );
  const isPreQualified = get(metaContext, 'meta.preQualified', false);

  const showTabsSummary =
    isStatusPage(status) || showSubPage || isPreQualifiedOfferExpired;

  const callCustomSchedulePreview = useCallback(async () => {
    const proposedPayments = get(metaContext, 'meta.calculator.proposedPayments');
    const creditObject = get(
      metaContext,
      'meta.calculator.installmentApprovedCreditTerms'
    );
    const { term, paymentTerm } = extractCreditTermInfo(creditObject);
    const { data } = await generatePaymentPlan({
      variables: {
        customerNumber: companyNumber,
        opportunityAmount: formatAmount(get(proposedPayments, 'amount', '')),
        billingFrequency: get(proposedPayments, 'billing_frequency', ''),
        term: term ? parseInt(term, 10) : 0,
        blindDiscount: parseFloat(get(metaContext, 'meta.calculator.subsidy', '')),
        paymentTerm: parseInt(paymentTerm, 10) || 0,
        isDollar: false,
        spiffRate: parseFloat(get(proposedPayments, 'spiff_rate', '0')),
      },
    });

    const proposal = get(data, 'generatePaymentPlan');
    const fileName = `${companyNumber}-payment-plan.pdf`;
    handleDownload(proposal, fileName);
  }, [metaContext, companyNumber, generatePaymentPlan]);

  const buyerPayoutText = useMemo(() => {
    const payoutText = get(response, 'payment', '');
    return payoutText.split('/');
  }, [response]);

  const renderSpiffData = (amount: string): JSX.Element => {
    if (ZERO_CURRENCY_REGEX.test(amount)) return <></>;
    return (
      <OutlinedPill
        text={`${amount} SPIFF`}
        variant="secondary"
        containerClassName="mt-2"
      />
    );
  };

  const renderCustomSchedulePaymentPlan = (): JSX.Element => {
    return (
      <>
        <NotesStack />
        <div
          role="button"
          onClick={callCustomSchedulePreview}
          tabIndex={0}
          className="break-all vp-text-link-xxsm text-vartana-blue-120 cursor-pointer border-none mt-2"
        >
          {VIEW_PAYMENT_PLAN}
        </div>
      </>
    );
  };

  const renderCustomScheduleSellerPayout = (isScreenSmall: boolean): JSX.Element => {
    // TODO mwahaj92 update using an interface
    const spiffAmountValue = get(
      metaContext,
      'meta.calculator.proposedPayments.spiff_amount',
      '0'
    );
    const docFee = get(
      metaContext,
      'meta.calculator.proposedPayments.vendor_application_fee_tooltip',
      '0'
    );
    const formattedSubsidy = get(
      metaContext,
      'meta.calculator.proposedPayments.formatted_discount_rate_tooltip',
      '0'
    );
    return (
      <>
        <div className="payout-payment-text">
          <Typography
            variant="heading24"
            color="color-blue-180"
            className="payout-amount-text"
          >
            {get(metaContext, 'meta.calculator.proposedPayments.net_payout')}
          </Typography>
        </div>

        <div className="flex gap-[0.12rem] items-center mt-1">
          <Typography
            variant="paragraph10"
            color="color-steel-140"
            className="payout-primary-text"
          >
            {SELLER_TOTAL_PAYOUT}
          </Typography>

          {docFee || formattedSubsidy
            ? renderFeeTooltip(docFee, formattedSubsidy, isScreenSmall)
            : null}
        </div>
        {!!spiffAmount && renderSpiffData(spiffAmountValue)}
      </>
    );
  };
  const shouldRenderInterestRatePill =
    shouldShowInterestRateToVendor && !!buyerInterestRate && get(response, 'payment');

  return (
    <>
      <VartanaLoader loading={isTabLoading} fullscreen={false} />
      <div className="installment-payment-tab w-full">
        {showTabsSummary ? (
          <TabsSummary
            status={status}
            onBackClick={toggleSubPage}
            onButtonClick={handleTertiaryButtonClick}
            isCrm={isCrm}
            companyNumber={companyNumber}
            appName={appName}
            isPreQualifiedOfferExpired={isPreQualifiedOfferExpired}
          />
        ) : (
          <div className="flex flex-col installment-tab-container w-[25.125rem] gap-4 h-[19.125rem]">
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row gap-2">
                  <StatusPill
                    status={getPillStatus(status)}
                    text={status}
                    compact={isCompactScreen(widthVariant, isCrm)}
                  />
                  {showCurrencyPill && <CurrencyPill compactPill currency={currency} />}
                </div>
                <TertiaryActions
                  actions={tertiaryActions}
                  onActionClick={handleTertiaryButtonClick}
                />
              </div>

              <Card
                padding="compact"
                parentContainerClassName="progen-card"
                content={(
                  <>
                    {isPreQualifiedTerms && (
                      <div className="pre-qualified-banner">
                        {PRE_QUALIFIED_OFFER_LABEL}
                      </div>
                    )}
                    <Calculator
                      onValueChange={handleValueChange}
                      setLoading={setIsLoading}
                      calculatorRef={calculatorRef}
                      isInstallment
                      setCanDownloadProposal={setCanDownloadProposal}
                      onFrequencyChange={handleFrequencyChange}
                      setError={setError}
                      error={error}
                    />
                  </>
                )}
              />
            </div>
            <div
              className={getPayoutContainerClassNames(
                !!error,
                isLoading,
                isCustomSchedule
              )}
            >
              {isLoading ? (
                <LoaderMd />
              ) : (
                <>
                  {error ? (
                    <div className="text-left space-y-2 error-message-container">
                      {get(error, 'message') || get(error, 'amount', '') ? (
                        <>
                          <AlertMessage
                            content={removeTrailingZeros(
                              get(error, 'message', '') || get(error, 'amount', '')
                            )}
                            type="error"
                            hyperLinkContent={
                              (get(error, 'amount', '').includes('value is above') ||
                                get(error, 'amount', '').includes(
                                  TCV_BELOW_APPROVED_ERROR
                                )) &&
                              !get(error, 'message', '').includes('No rates') &&
                              !isPreQualified
                                ? 'Click here to get this limit approved'
                                : ''
                            }
                            onHyperLinkClick={handleGetHigherLimit}
                            hyperLinkDisabled={creatingChangeRequest}
                          />
                          {creatingChangeRequest && (
                            <div className="w-full flex justify-center mt-5">
                              <LoaderMd />
                            </div>
                          )}
                        </>
                      ) : null}
                      {get(error, 'subsidy', false) ? (
                        <div>
                          <AlertMessage
                            content={get(error, 'subsidy').replace('.00', '')}
                            type="error"
                          />
                        </div>
                      ) : null}
                      {get(error, 'term', false) ? (
                        <div className={get(error, 'term', false) ? 'mt-2' : ''}>
                          <AlertMessage content={get(error, 'term', '')} type="error" />
                        </div>
                      ) : null}
                      {get(error, 'spiff', false) ? (
                        <div className={get(error, 'spiff', false) ? 'mt-2' : ''}>
                          <AlertMessage
                            content={get(error, 'spiff').replace('.00', '')}
                            type="error"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="flex flex-col gap-2 mb-4">
                      <div className="w-full flex flex-row gap-4 self-stretch">
                        <div
                          className={`flex flex-col items-center w-[calc(100%-2.063rem)] ${
                            showSpiff ? '' : 'justify-center'
                          }`}
                        >
                          {isCustomSchedule ? (
                            renderCustomSchedulePaymentPlan()
                          ) : (
                            <>
                              <Typography
                                variant="paragraph10"
                                color={
                                  get(response, 'payment')
                                    ? 'color-steel-140'
                                    : 'color-gray-110'
                                }
                                className="payout-primary-text"
                              >
                                Buyer pays
                              </Typography>

                              {get(response, 'payment') ? (
                                <div
                                  className={`payout-payment-text mt-1 ${
                                    shouldShowInterestRateToVendor ? 'mb-1.5' : 'mb-1'
                                  }`}
                                >
                                  <Typography
                                    variant="heading24"
                                    color="color-blue-180"
                                    className="payout-amount-text"
                                  >
                                    {buyerPayoutText[0]}
                                  </Typography>
                                  {shouldShowInterestRateToVendor && (
                                    <Typography
                                      variant="paragraph12"
                                      color="color-blue-180"
                                      className="payout-amount-text-small"
                                    >
                                      {`/${buyerPayoutText[1]}`}
                                    </Typography>
                                  )}
                                </div>
                              ) : (
                                <SvgIcon
                                  name="more_horiz"
                                  height="2rem"
                                  width="2rem"
                                  fill="#D9D9D9"
                                />
                              )}
                              <div className="flex gap-[0.12rem] mt-0.5">
                                {shouldRenderInterestRatePill ? (
                                  <OutlinedPill
                                    text={buyerInterestRate}
                                    variant="primary"
                                  />
                                ) : (
                                  <Typography
                                    variant="paragraph10"
                                    color={
                                      get(response, 'payment')
                                        ? 'color-steel-140'
                                        : 'color-gray-110'
                                    }
                                    className="payout-primary-text"
                                  >
                                    {frequencyText}
                                  </Typography>
                                )}
                              </div>
                            </>
                          )}
                          {renderGuaranteeStatus(
                            get(metaContext, 'meta.calculator'),
                            `mt-2.5 ${
                              isSmallScreen(widthVariant, isCrm) ? ' translate-x-2' : ''
                            }`,
                            isCustomSchedule || get(response, 'remittance')
                              ? 'color-steel-140'
                              : 'color-gray-110',
                            isSmallScreen(widthVariant, isCrm)
                          )}
                        </div>
                        <div className="bg-gray-300 w-[0.125rem] h-full" />
                        <div className="flex flex-col items-center w-[calc(100%-2.063rem)]">
                          {isCustomSchedule ? (
                            renderCustomScheduleSellerPayout(
                              isSmallScreen(widthVariant, isCrm)
                            )
                          ) : (
                            <>
                              <Typography
                                variant="paragraph10"
                                color={
                                  get(response, 'remittance')
                                    ? 'color-steel-140'
                                    : 'color-gray-110'
                                }
                                className="payout-primary-text"
                              >
                                Seller receives
                              </Typography>

                              {get(response, 'remittance') ? (
                                <div className="payout-payment-text mt-1 mb-1.5">
                                  <Typography
                                    variant="heading24"
                                    color="color-blue-180"
                                    className="payout-amount-text"
                                  >
                                    {get(response, 'remittance')}
                                  </Typography>
                                </div>
                              ) : (
                                <SvgIcon
                                  name="more_horiz"
                                  height="2rem"
                                  width="2rem"
                                  fill="#D9D9D9"
                                />
                              )}
                              <div className="flex gap-[0.12rem] items-center">
                                <Typography
                                  variant="paragraph10"
                                  color={
                                    get(response, 'remittance')
                                      ? 'color-steel-140'
                                      : 'color-gray-110'
                                  }
                                  className="payout-primary-text"
                                >
                                  {get(metaContext, 'meta.calculator.spiffMode') !==
                                  'none'
                                    ? 'total payout'
                                    : `after ${vendorFee} fee`}
                                </Typography>
                                {hasFee &&
                                  renderFeeTooltip(
                                    documentationFee,
                                    discountTooltip,
                                    isSmallScreen(widthVariant, isCrm)
                                  )}
                              </div>
                              {renderSpiffAmount &&
                                renderSpiffData(get(response, 'spiff_amount', ''))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InstallmentTab;
